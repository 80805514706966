import classNames from 'classnames'
import useDependentState from 'lib/hooks/useDependentState'
import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  Ref,
  TextareaHTMLAttributes,
  VFC,
} from 'react'

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  border?: boolean
  onValueChange?: (value: string) => void
  testId?: string
  error?: boolean
}

export const Textarea: VFC<TextareaProps> = forwardRef(
  (
    {
      border,
      className,
      name,
      onBlur,
      onChange,
      onValueChange,
      value,
      testId,
      error,
      ...props
    },
    ref: Ref<HTMLTextAreaElement>
  ) => {
    const [currentValue, setCurrentValue] = useDependentState<string>(
      value ? String(value) : ''
    )

    const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
      if (onValueChange) {
        onValueChange(currentValue)
      }

      if (onBlur) {
        onBlur(e)
      }
    }

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCurrentValue(e.target.value)

      if (onChange && !onValueChange) {
        onChange(e)
      }
    }

    return (
      <textarea
        ref={ref}
        name={name}
        value={currentValue}
        data-testid={testId || name}
        className={classNames(
          className,
          'block py-2 px-3 rounded w-full text-black placeholder-darkGray focus:outline-none focus:ring',
          {
            'border border-darkGray': border,
            'border border-red': error,
          }
        )}
        onBlur={handleBlur}
        onChange={handleChange}
        rows={5}
        {...props}
      />
    )
  }
)

Textarea.displayName = 'Textarea'

export default Textarea
