import classNames from 'classnames'
import { VFC } from 'react'

type CheckBoxProps = {
  label?: string
  name?: string
  value?: string | number | readonly string[] | undefined
  noBold?: boolean
  disabled?: boolean
  testId?: string
  onChange?: (...arg: any) => void
  color?: string
  checked: boolean
}

const CheckBox: VFC<CheckBoxProps> = ({
  label,
  name,
  value,
  noBold,
  disabled,
  testId,
  onChange,
  color,
  checked,
}) => {
  const textColor = disabled ? 'text-darkGray' : color
  return (
    <div className="inline-block">
      <label
        className={classNames(
          'flex items-center',
          { 'font-bold': !noBold },
          textColor
        )}
      >
        <input
          type="checkbox"
          onChange={onChange}
          disabled={disabled}
          name={name}
          className="mr-2"
          value={value}
          defaultChecked={!!value}
          checked={checked}
          data-testid={testId || name}
        />
        {label}
      </label>
    </div>
  )
}

export default CheckBox
